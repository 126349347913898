import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/datamigrators.github.io/datamigrators.github.io/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`This page describes Data Migrators’ policy on server malware protection.`}</p>
    </PageDescription>
    <AnchorLinks mdxType="AnchorLinks">
  <AnchorLink mdxType="AnchorLink">Overview</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Purpose</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Scope</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Policy</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Policy Compliance</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Exceptions</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Non-compliance</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Related Documents</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Definitions and Terms</AnchorLink>
    </AnchorLinks>
    <h2>{`Overview`}</h2>
    <p>{`Data Migrators is entrusted with the responsibility to provide
professional management of clients servers as outlined in each of the
contracts with its customers. Inherent in this responsibility is an
obligation to provide appropriate protection against malware threats,
such as viruses and spyware applications. Effective implementation of
this policy will limit the exposure and effect of common malware threats
to the systems they cover.`}</p>
    <h2>{`Purpose`}</h2>
    <p>{`The purpose of this policy is to outline which server systems are
required to have anti-virus and/or anti-spyware applications.`}</p>
    <h2>{`Scope`}</h2>
    <p>{`This policy applies to all servers that Data Migrators is responsible
to manage. This explicitly includes any system for which Data Migrators
has a contractual obligation to administer. This also includes all server
systems setup for internal use by Data Migrators, regardless of whether
Data Migrators retains administrative obligation or not.`}</p>
    <h2>{`Policy`}</h2>
    <p>{`Data Migrators operations staff will adhere to this policy to
determine which servers will have anti-virus and/or anti-spyware
applications installed on them and to deploy such applications as
appropriate.`}</p>
    <h3>{`Anti-virus`}</h3>
    <p>{`All servers MUST have an anti-virus application installed that offers
real-time scanning protection to files and applications running on the
target system if they meet one or more of the following conditions:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Non-administrative users have remote access capability`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`The system is a file server`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`NBT/Microsoft Share access is open to this server from systems used
by non-administrative users`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`HTTP/FTP access is open from the Internet`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Other “risky” protocols/applications are available to this system
from the Internet at the discretion of the Data Migrators Security
Administrator`}</p>
      </li>
    </ul>
    <p>{`All servers SHOULD have an anti-virus application installed that offers
real-time scanning protection to files and applications running on the
target system if they meet one or more of the following conditions:`}</p>
    <ul>
      <li parentName="ul">{`Outbound web access is available from the system`}</li>
    </ul>
    <h3>{`Mail Server Anti-virus`}</h3>
    <p>{`If the target system is a mail server it MUST have either an external or
internal anti-virus scanning application that scans all mail destined to
and from the mail server. Local anti-virus scanning applications MAY be
disabled during backups if an external anti-virus application still
scans inbound emails while the backup is being performed.`}</p>
    <h3>{`Anti-spyware`}</h3>
    <p>{`All servers MUST have an anti-spyware application installed that offers
real-time protection to the target system if they meet one or more of
the following conditions:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Any system where non-technical or non-administrative users have
remote access to the system and ANY outbound access is permitted to
the Internet`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Any system where non-technical or non-administrative users have the
ability to install software on their own`}</p>
      </li>
    </ul>
    <h3>{`Notable Exceptions`}</h3>
    <p>{`An exception to the above standards will generally be granted with
minimal resistance and documentation if one of the following notable
conditions apply to this system:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`The system is a SQL server`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`The system is used as a dedicated mail server`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`The system is not a Windows based platform`}</p>
      </li>
    </ul>
    <h2>{`Policy Compliance`}</h2>
    <h3>{`Compliance Measurement`}</h3>
    <p>{`The Infosec team will verify compliance to this policy through various
methods, including but not limited to, business tool reports, internal
and external audits, and feedback to the policy owner.`}</p>
    <h2>{`Exceptions`}</h2>
    <p>{`Any exception to the policy must be approved by the Infosec team in
advance.`}</p>
    <h2>{`Non-Compliance`}</h2>
    <p>{`An employee found to have violated this policy may be subject to
disciplinary action, up to and including termination of employment.`}</p>
    <h2>{`Related Documents`}</h2>
    <p>{`None.`}</p>
    <h2>{`Definitions and Terms`}</h2>
    <p>{`The following definition and terms can be found in the `}<a parentName="p" {...{
        "href": "https://www.sans.org/security-resources/glossary-of-terms/"
      }}>{`SANS Glossary`}</a>{`:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Malware`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Spyware`}</p>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      